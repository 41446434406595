/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.nav-projects').on( 'click', function(){
            $(this).toggleClass('clicked');
            $('.wa-content-container').toggle(0);
            $('.wa-flyout-container').slideToggle(0);
            if( $(this).hasClass('clicked') ) {
            }
            return false;
        });
        $('.more-link').on( 'click', function(){
            $(this).toggle(0);
            $(this).parents('.wa-content-excerpt').next('.wa-content-full').slideToggle(0);
            return false;
        });

        // $(".wa-inline-colorbox").each(function(){
        //     $(this).colorbox({
        //         inline:true,
        //         width:"90%",
        //         height:"95%",
        //         onClosed:function(){
        //             $('.wa-project-container').slideDown();
        //             $('.wa-owl-container').slideUp();
        //         }
        //     });
        // });
        $(".wa-project-gallery").each(function(i){
            var galleryClass = '.' + $(this).attr('data-gallery-class');
            // var lightbox = $(this).find(galleryClass).simpleLightbox({
            //     captionPosition: 'outside',
            //     widthRatio: 0.8,
            //     heightRatio: 0.7
            // });
            // $('.sl-image img').on('click', function(){
            //     console.log('oipjqwd');
            //     lightbox.next();
            // });

            // console.log(galleryClass);
            var lightbox = $(this).find(galleryClass).colorbox({
                // inline:true,
                // width:"90%",
                // height:"95%",
                // onClosed:function(){
                //     $('.wa-project-container').slideDown();
                //     $('.wa-owl-container').slideUp();
                // }
                rel: galleryClass,
                scrolling: false,
                fixed: true,
                overlayClose: false,
                transition:"none",
                width:"85%",
                height:"95%",
                current:"{current}|{total}",
                previous: "&#60;",
                next: "&#62;"
            });

        });
        $(".wa-project-container").each(function(i){
            var owl = $(this).find('.owl-carousel').owlCarousel({
                items : 1,
                lazyLoad: true,
                nav: false
            });
            console.log(owl);
        });


        // jQuery(".wa-simplelightbox").colorbox({rel:'wa-simplelightbox', transition:"none", width:"85%", height:"95%", current:"{current}/{total}", previous: "&#60;", next: "&#62;"});

        // $('.wa-project-gallery').on('click', function(){
        //     var $this = $(this);
        //     var containerId = $this.parent().parent('.wa-project-container');
        //     var owlId = $this.parent().parent('.wa-project-container').next('.wa-owl-container');
        //     $this.parent().parent('.wa-project-container').slideUp();
        //     $this.parent().parent('.wa-project-container').next('.wa-owl-container').slideDown( 0, function() {
        //         owl = $(this).find('.owl-carousel');
        //         owl.owlCarousel({
        //             items : 1,
        //             lazyLoad: true,
        //             nav: false,
        //             onInitialized: function(event){
        //                 owl.next('.wa-owl-navigation').find('.wa-item-count').text(this._items.length);
        //                 owl.next('.wa-owl-navigation').find('.wa-description').text(owl.find('.active img').attr('data-description'));
        //             }
        //         });
        //         owl.on('changed.owl.carousel', function(event) {
        //             owl.next('.wa-owl-navigation').find('.wa-description').text(owl.find('.active img').attr('data-description'));
        //             owl.next('.wa-owl-navigation').find('.wa-item').text(event.page.index + 1);
        //         });
        //         owl.next('.wa-owl-navigation').find('.wa-next').on('click', function() {
        //             owl.trigger('next.owl.carousel');
        //         });
        //         owl.next('.wa-owl-navigation').find('.wa-prev').on('click', function() {
        //             owl.trigger('prev.owl.carousel');
        //         });
        //     });
        //     $this.parent().parent('.wa-project-container').next('.wa-owl-container').find('.wa-owl-close').on('click', function(){
        //         containerId.slideDown();
        //         owlId.slideUp();
        //     });
        // });

        $('.wa-mobile-navi-container').append( $('.wa-main-menu').html() );
        $('.wa-mobile-navi-container .nav-projects').append( $('.wa-mobile-categories').html() );

        //mobile
        $(".wa-mobile-toggler, .wa-mobile-close").on('click', function () {
            $(".wa-mobile-navi-container").toggle();
            $(".wa-toggle").toggleClass("active");
        });

      }
    },
    // Home page
    'home': {
        init: function() {
          // JavaScript to be fired on the home page
          $('body').prepend($('.wa-homepage-fullsize'));
        },
        finalize: function() {
          // JavaScript to be fired on the home page, after the init JS
          $('.nav-projects').removeClass('nav-projects');
          $('.nav-projects').on( 'click', function(){
              return true;

          });
          $('.wa-hompage-fullsize-image').on('click', function(){
              window.location.href = '/projekte/';
          });
        }
    },
    'homepage': {
        init: function() {
          // JavaScript to be fired on the home page
          $('body').prepend($('.wa-homepage-fullsize'));
        },
        finalize: function() {
          // JavaScript to be fired on the home page, after the init JS
          $('.nav-projects').removeClass('nav-projects');
          $('.nav-projects').on( 'click', function(){
              return true;

          });
        }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
